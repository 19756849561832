import React from 'react';
import classNames from 'classnames';
import SidebarNav from './SidebarNav';
import Logo from '~/features/shared/components/Logo';
import { MEDIUM_BREAKPOINT, MOBILE_BREAKPOINT } from '~/features/shared/constants';

type SidebarProps = {
  open: boolean;
};

function Sidebar({ open }: SidebarProps): JSX.Element {
  return (
    <div className={classNames('leftside-menu d-print-none', { open })}>
      <div className="logo-container">
        <Logo width="100%" height="70px" variant="light" />
      </div>

      <div className="leftside-menu-container">
        <SidebarNav />
      </div>
      <style jsx>{`
        @media (max-width: ${MEDIUM_BREAKPOINT}px) {
          .leftside-menu {
            display: block;
            margin-top: 70px;
            padding-top: 10px;
            border-right: 1px solid #2b4881;
            z-index: 99999 !important;
          }
          .logo-container {
            display: none;
          }
        }

        .leftside-menu {
          transform: translate(-260px);
          transition: transform 500ms;
        }

        .leftside-menu.open {
          transform: translate(0);
        }

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          .leftside-menu {
            transform: translate(-100vw);
          }
          .leftside-menu.open {
            transform: translate(0);
          }
        }
      `}</style>
    </div>
  );
}

export default Sidebar;
