var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3.13.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import clientConfig from './src/clientConfig';

Sentry.init({
  dsn: clientConfig.sentry.dsn,
  environment: clientConfig.environment,
  release: clientConfig.version,
  replaysSessionSampleRate: clientConfig.sentry.tracesSampleRate,
  replaysOnErrorSampleRate: 1,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({ themeLight: true, autoInject: false })
  ],
  tracesSampleRate: clientConfig.sentry.tracesSampleRate,
  enabled: true, // clientConfig.environment !== 'local',
  normalizeDepth: 10,
  tracesSampler: (samplingContext) => {
    // Examine provided context data (including parent decision, if any) along
    // with anything in the global namespace to compute the sample rate or
    // sampling decision for this transaction

    if (samplingContext.location.search.includes('sample=true')) {
      return 1;
    }
    // if ('...') {
    //   // These are less important or happen much more frequently - only take 1%
    //   return 0.01;
    // }
    // if ('...') {
    //   // These aren't something worth tracking - drop all transactions like this
    //   return 0;
    // }
    return clientConfig.sentry.tracesSampleRate;
  }
});
