import { checkUpcomingSalaryErrors } from './checkUpcomingSalaryErrors';
export var InvoiceStatusEnum;
(function (InvoiceStatusEnum) {
    InvoiceStatusEnum["APPROVED_BY_COMPANY"] = "APPROVED_BY_COMPANY";
    InvoiceStatusEnum["AWAITING_TASK_APPROVAL"] = "AWAITING_TASK_APPROVAL";
    InvoiceStatusEnum["CANCELLED"] = "CANCELLED";
    InvoiceStatusEnum["COMPANY_DISPUTE"] = "COMPANY_DISPUTE";
    InvoiceStatusEnum["DEBT_COLLECTION"] = "DEBT_COLLECTION";
    InvoiceStatusEnum["DENIED_BY_COMPANY"] = "DENIED_BY_COMPANY";
    InvoiceStatusEnum["DRAFT"] = "DRAFT";
    InvoiceStatusEnum["LATE_PAYMENT"] = "LATE_PAYMENT";
    InvoiceStatusEnum["MORE_INFO_NEEDED"] = "MORE_INFO_NEEDED";
    InvoiceStatusEnum["PAID"] = "PAID";
    InvoiceStatusEnum["PAYMENT_ON_HOLD"] = "PAYMENT_ON_HOLD";
    InvoiceStatusEnum["PAYMENT_RECEIVED"] = "PAYMENT_RECEIVED";
    InvoiceStatusEnum["PENDING"] = "PENDING";
    InvoiceStatusEnum["SALARY_PAID"] = "SALARY_PAID";
    InvoiceStatusEnum["SALARY_PAID_CUSTOMER_NOT_PAID"] = "SALARY_PAID_CUSTOMER_NOT_PAID";
    InvoiceStatusEnum["SALARY_PAID_CUSTOMER_PAID"] = "SALARY_PAID_CUSTOMER_PAID";
    InvoiceStatusEnum["SENT"] = "SENT";
    InvoiceStatusEnum["SENT_TO_COMPANY"] = "SENT_TO_COMPANY";
    InvoiceStatusEnum["SENT_TO_COMPANY_AWAITING_APPROVAL"] = "SENT_TO_COMPANY_AWAITING_APPROVAL";
    InvoiceStatusEnum["SENT_TO_COMPANY_CONTRACT_MADE"] = "SENT_TO_COMPANY_CONTRACT_MADE";
    InvoiceStatusEnum["SENT_TO_COMPANY_NEEDS_CONTRACT"] = "SENT_TO_COMPANY_NEEDS_CONTRACT";
})(InvoiceStatusEnum || (InvoiceStatusEnum = {}));
const invoiceIsEarlyPayment = (invoice) => invoice.earlyPayment || invoice.customer.company?.earlyPayment;
export function checkSalaryErrors({ user, payrollUser, mileageTotalDkk, selectedInvoices, selectedInvoiceSubtotalDkk, hasPayslip }) {
    const errors = [];
    if (payrollUser.status === 'PENDING') {
        errors.push('errors.notSynced');
    }
    errors.push(...checkUpcomingSalaryErrors({ user }));
    // if (!(user.contractSignatureProvider === 'contractbook') && !user.hasSignedContract) {
    //   errors.push('errors.notSignedContract');
    // }
    if (selectedInvoices.find((x) => !x.paidAmountDkk && !invoiceIsEarlyPayment(x))) {
        errors.push('errors.notAllInvoicesHavePaid');
    }
    if (!user.address) {
        errors.push('errors.userDoesNotHaveValidAddress');
    }
    if (selectedInvoices.length === 0) {
        errors.push('errors.noInvoicesHaveSelected');
    }
    if (!hasPayslip) {
        errors.push('errors.noPayslip');
    }
    return errors;
}
export default checkSalaryErrors;
